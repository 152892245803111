<template>
  <table
    class="table table-hover"
    :class="{'table-sm': small}"
  >
    <template v-if="fields.length > 0">
      <thead>
        <tr>
          <th
            v-for="(field, idx) in fields"
            :key="`th-${idx}`"
            :aria-sort="getSortDirection(field)"
            :class="{ 'clickable-th': field.sortable }"
            scope="col"
            @click="toggleSort(field)"
          >
            <div class="table-header">
              <template v-if="field.sortable">
                <span style="float: left; margin-right: 3px;">
                  <div style="display: flex; flex-direction: column;">
                    <b-icon
                      :icon="isSortedBy(getSortKey(field)) ? 'CaretUpFill' : 'CaretUp'"
                      variant="primary"
                      scale="0.95"
                      style="margin-bottom: -3px;"
                    />
                    <b-icon
                      :icon="isSortedBy(`${getSortKey(field)}-`) ? 'CaretDownFill' : 'CaretDown'"
                      variant="primary"
                      scale="0.95"
                      style="margin-top: -3px;"
                    />
                  </div>
                </span>
              </template>
              <slot
                v-if="$slots[`head(${field.key})`]"
                :name="`head(${field.key})`"
                :item="field"
              />
              <template v-else>
                {{ getTableHeader(field) }}
              </template>   
            </div>  
          </th>
        </tr>
      </thead>
    </template>
    <template v-if="!busy && items.length > 0">
      <tbody
        class="table-group-divider"
        style="border-top-color: #c9c9c9;"
      >
        <tr
          v-for="(item, tridx) in items"
          :key="`tr-${tridx}`"
          :class="getRowClass(item)"
          @click="handleRowClicked(item)"
        >
          <td
            v-for="(field, tdidx) in fields"
            :key="`td-${tdidx}`"
            scope="col"
            class="align-middle"
            :class="{'fixed-size': String(displayValue(item, field)).length < 4}"
          >
            <template v-if="!$slots[`cell(${field['key']})`]">
              {{ displayValue(item, field) }}
            </template>
            <template v-else>
              <slot
                :name="`cell(${field['key']})`"
                :item="item"
              />
            </template>
          </td>
        </tr>
      </tbody>
    </template>
    <template v-else-if="busy && $slots['table-busy']">
      <tr>
        <td :colspan="fields.length">
          <div class="justify-content-center">
            <slot name="table-busy" />
          </div>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import { stringToTableHeader } from '../../core/Functions' 
import _ from 'lodash'

export default {
    props: {
        busy: {
            type: Boolean,
            default: false
        },
        currentPage: {
            type: Number,
            default: 1
        },
        currentSort: {
            type: String,
            default: null
        },
        fields: {
            type: Array,
            default: () => []
        },
        filter: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
        perPage: {
            type: Number,
            default: 1
        },
        primaryKey: {
            type: String,
            default: null
        },
        tbodyTrClass: {
          type: [String, Function],
          default: ''
        },
        type: {
            type: String,
            default: 'button'
        },
        small: {
            type: Boolean,
            default: false
        }
    },

    emits: ['row-clicked', 'update:currentSort'],

    methods: {
        displayValue(item, field){
            if(!field['formatter'] || typeof field['formatter'] !== 'function'){
                return _.get(item, field['key'])
            }
        
            return field['formatter'](_.get(item, field['key']))
        },

        isSortedBy(param){
            return this.currentSort == param
        },

        setCurrentSort(param) {
            this.$emit('update:currentSort', param);
        },

        getSortDirection(field){
          if(this.isSortedBy(this.getSortKey(field))){
            return 'asc'
          } else if(this.isSortedBy(`${this.getSortKey(field)}-`)){
            return 'desc'
          }

          return 'none'
        },

        getSortKey(field){
            if(!!field.sort_key && field.sort_key.length > 0){
                return field.sort_key
            }

            return field.key
        },

        getTableHeader(field){
            if(!!field.label && field.label.length > 0) {
                return field.label
            } else if(!!field.key && field.key.length > 0) {
                return stringToTableHeader(field.key)
            } 

            return ''
        },

        handleRowClicked(row) {
          this.$emit('row-clicked', row);
        },

        getRowClass(row) {
          if (typeof this.tbodyTrClass === 'function') {
            return this.tbodyTrClass(row);
          } else {
            return this.tbodyTrClass || '';
          }
        },

        toggleSort(field){
          // set defaults
          let desc = false
          field = this.getSortKey(field)
         
          // make sure current sort is not null
          if(!!this.currentSort){
            let currentSortFieldname = this.currentSort
            
            // set descending flag if current sort is descending
            if (this.currentSort.endsWith("-")) {
              desc = true
              // grab the base name
              currentSortFieldname = this.currentSort.slice(0, -1)
            }

            // if the current sort is the same as the newly selected sort
            if(field == currentSortFieldname){
              // invert the direction
              desc = !desc
              if(desc){
                this.setCurrentSort(`${field}-`)
                return
              }
            } 
          }

          this.setCurrentSort(field)
        }
    },
}
</script>
<style lang="scss" scoped>
.fixed-size {
    width: 100px;
}
.table-header {
    display: flex;
    align-items: center;
}
</style>